// import components and libraries

import React from 'react';
// import List from './List';

// import icons and images

// import ListIconNo1 from '../../../assets/home/ListIconNo1.svg';
// import ListIconNo2 from '../../../assets/home/ListIconNo2.svg';
// import ListIconNo3 from '../../../assets/home/ListIconNo3.svg';
// import ListIconNo4 from '../../../assets/home/ListIconNo4.svg';
// import ListIconNo5 from '../../../assets/home/ListIconNo5.svg';

export default function ServicesList() {
  return (
    <div className="listx">
      {/* <List
        sectionId="BuyALaser"
        title="Buy A Pre-Owned Laser"
        description="We sell high quality and brand premium pre-owned laser, they are 
checked and revised by independent and experienced service engineers. 
Find the right laser with us and save 50% on average compared to new. "
        icon={ListIconNo1}
        number="1"
        background="#02225E"
        titleColor="#02225E"
      />
      <List
        sectionId="SellYourLaser"
        title="Sell your Laser"
        description="The process of selling your used medical laser is very easy. Fill the form below, and after review of your machine, we will either purpose you a purchase offer within 48 hours or we will list it on our marketplace and actively sell it with our partners and clients."
        icon={ListIconNo2}
        number="2"
        background="#B3881D"
        titleColor="#B3881D"
      />
      <List
        sectionId="MaintainYourLaser"
        title="Maintain Your Laser"
        description="All medical laser equipment require a periodical preventive maintenance 
at least once a year, and this to avoid costly breakdowns and to 
guarantee good clinical functioning. For the moment we offer a laser 
repair and preventive maintenance with flashlamp change and 
handpieces refurbishment of certain brands. Do not hesitate to contact us
for a quote or for more information
"
        icon={ListIconNo3}
        number="3"
        background="#75CB41"
        titleColor="#75CB41"
      />
      <List
        sectionId="RentALaser"
        title="Rent a Laser"
        description="Renting an equipment is an ideal step before buying one, it's also a way 
to avoid committing to an expensive investment without having visibility 
on your activity of aesthetic medicine. You can rent according to your 
needs with an option to buy. Contact us for a quote and to know the 
availability of the technology you are looking for.
"
        icon={ListIconNo4}
        number="4"
        background="#42C6FF"
        titleColor="#42C6FF"
      />

      <List
        sectionId="DeliveryAndMove"
        title="Delivery and move"
        description="The transport of lasers requires special attention due to the optical 
alignment of the laser beams, which is why we take care of the transport 
ourselves under the right conditions. We are also available to 
manufacturers as a subcontractor "
        icon={ListIconNo5}
        number="5"
        background="#FD8833"
        titleColor="#FD8833"
      /> */}
    </div>
  );
}
