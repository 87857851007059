import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import HeroSection from '../components/home/HeroSection';
import ContactUs from '../components/home/ContactSection';
import Testimonials from '../components/home/TestimonialsSection/Testimonials';
import Index from '../components/home/ServicesSection/Index';

// Home Component
export default function Home() {
  const { pathname } = useLocation();
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    const screenWidth = window.innerWidth;
    if (pathname.includes('lasers')) {
      window.scrollTo({
        top: screenWidth < 600 ? 700 : 930,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [pathname]);
  return (
    <div style={{ overflow: 'hidden' }}>
      <Header />
      <HeroSection />
      <Testimonials />
      <div id="lasers">
        <Index flag={flag} setFlag={setFlag} />
      </div>
      <div id="about" style={{ position: 'absolute', marginTop: '-60px' }} />
      <ContactUs flag={flag} />
      <Footer />
    </div>
  );
}
