// import components and libraries

// import { fontWeight } from '@mui/system';
import i18n from 'i18next';
import React from 'react';
// import useEffect from 'react';

// import icons and images
// import { useTranslation } from 'react-i18next';

// import herosearch from '../../assets/home/herosearch.svg';
import heroSection from '../../assets/home/heroSection.svg';
// const { i18n, t } = useTranslation();
// const { language } = i18n;
// console.log('check', language);

// import heroSectionImg from '../../assets/home/MainHeroSectionImg.png';
// import InputSearch from '../common/InputSearch';

export default function HeroSection() {
  // const { t } = useTranslation();

  const currentLanguage = i18n?.language;
  // console.log('Currentlanguage:', currentLanguage);
  const scr = window.innerWidth;

  return (
    <div className="home_main">
      <div className="home_main_inner">
        {/* <span className="home_span_text">USED LASER FOR ALL</span> */}
        {/* <h1 className="home_heading">
              <span>DERMAZON</span> {t('is a worldwide marketplace')}
            </h1> */}
        {/* <p className="home_text_p">
          {' '}
          Bienvenue sur le site internet deDermazon, Nous sommes une entreprise basée à Paris
          spécialisée dans la reprise et larevente de dispositif à base d'énergie et delasers
          d'occasion, destinés aux professionnels de lamédecine esthétique du monde entier. Notre
          expérience et notre expertise technique nous permettent de proposer des équipements de
          qualité supérieure et de marques premium à des prix abordables.
        </p> */}

        {/* <div className="input_component">
            <InputSearch />
          </div> */}

        <div className="home_right">
          <img className="heroBG" src={scr > 1000 ? heroSection : heroSection} alt="" />
          {/* <p className="home_text_pi">
            Bienvenue sur le site internet deDermazon, Nous sommes une entreprise basée à Paris
            spécialisée dans
            <span style={{ fontWeight: '600' }}> la reprise et larevente </span>
            de dispositif à based'énergie et
            <span style={{ fontWeight: '600' }}> delaser d'occasion, </span>
            destinés aux professionnels de la
            <span style={{ fontWeight: '600' }}>médecine esthétique </span>
            du monde entier.
            <br />
            <br />
            Notre expérience et notre expertise technique nous permettent de proposer des
            équipements de qualité supérieure et de marques premium à des prix abordables.
          </p> */}
          {currentLanguage === 'fr' ? (
            <p className="home_text_pi">
              Bienvenue sur le site internet de Dermazon, Nous sommes une entreprise basée à Paris
              spécialisée dans
              <span style={{ fontWeight: '600' }}> la reprise et la revente </span>
              de dispositif à base d&apos;énergie et
              <span style={{ fontWeight: '600' }}> de laser d&apos;occasion, </span>
              destinés aux professionnels de la
              <span style={{ fontWeight: '600' }}> médecine esthétique </span>
              du monde entier.
              <br />
              <br />
              Notre expérience et notre expertise technique nous permettent de proposer des
              équipements de qualité supérieure et de marques premium à des prix abordables.
            </p>
          ) : (
            <p className="home_text_pi">
              Welcome to the Dermazon website ,We are a Paris based company specialising in the
              <span style={{ fontWeight: '600' }}> trade-in and resale </span>
              of used energy based devices and lasers to
              <span style={{ fontWeight: '600' }}> aesthetic medicine professionals </span>
              worldwide.
              <br />
              <br />
              Our experience and technical expertise enable us to offer high quality, premium
              branded equipment at affordable prices.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
