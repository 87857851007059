import React from 'react';
import Notice from '../components/LegalNotices/Notice';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

function LegalNotices() {
  return (
    <div>
      <Header />
      <Notice />
      <Footer />
    </div>
  );
}

export default LegalNotices;
