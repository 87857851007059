// import components and libraries

import React from 'react';
import { Link } from 'react-router-dom';
// import ServiceImageSlider from './ServiceImageSlider';
// import laserMachineHome from '../../../assets/home/laserMachineHome.png';

export default function SingleService(props) {
  const {
    reverse,
    bgColor,
    icon,
    title,
    btnBg,
    btnText,
    serviceDescription,
    serviceDescription2,
    sectionId,
    Path,
    image,
    showImage,
    setFlag,
  } = props;
  const firstWord = title.split(' ')[0];
  function removeFirstWord() {
    const indexOfSpace = title.indexOf(' ');
    if (indexOfSpace === -1) {
      return '';
    }
    return title.slice(indexOfSpace + 1);
  }
  const scroll = () => {
    const section = document.querySelector(`#${'about'}`);
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  return (
    <div
      className={reverse ? 'single_service_container_reverse' : 'single_service_container'}
      id={sectionId}
    >
      <div className={reverse ? 'single_service_left_reverse' : 'single_service_left'}>
        {reverse || showImage ? (
          <img
            src={image}
            alt="Single Service"
            style={{
              height: '450px',
              width: '450px',
              marginRight: '47%',
            }}
          />
        ) : (
          <img
            alt="Single Service"
            className="laserMachineHomeImg"
            src={image}
            style={{
              // background: 'red',
              height: '450px',
              width: '450px',
              marginRight: '20%',
            }}
          />
          // <ServiceImageSlider />
        )}
      </div>
      <div className="single_service_right">
        <div className="single_servise_controler">
          <div className="single_servise_controler_div">
            <div
              className="single_service_icon_div"
              style={{
                backgroundColor: bgColor && bgColor,
              }}
            >
              <img className="service_icons_img" src={icon} alt="Service Icon" />
            </div>
            {/* <h1 className="single_service_title">
              <span
                style={{
                  color: btnBg,
                }}
              >
                {`${firstWord} ${' '}`}
              </span>
              <p style={{ color: 'black', display: 'inline' }}>{removeFirstWord(title)}</p>
            </h1> */}
            <h1 className="single_service_title">
              {firstWord === 'Entretien' ? (
                <>
                  <span style={{ color: btnBg }}>
                    {`${firstWord} ${title.split(' ').slice(1, 3).join(' ')} `}
                  </span>
                  <span style={{ color: 'black' }}>{title.split(' ').slice(3).join(' ')}</span>
                </>
              ) : (
                <>
                  <span style={{ color: btnBg }}>{`${firstWord} ${' '}`}</span>
                  <span style={{ color: 'black', display: 'inline' }}>
                    {removeFirstWord(title)}
                  </span>
                </>
              )}
            </h1>
          </div>
        </div>

        <p className="single_service_text_p">{serviceDescription}</p>
        <p className="single_service_text_p" id="singleDesTwo">
          {serviceDescription2}
        </p>
        <div
          className={reverse ? 'single_service_left_reverse_mobile' : 'single_service_left_mobile'}
        >
          {reverse || showImage ? (
            <img
              src={image}
              alt="Single Service"
              style={{
                height: '450px',
                width: '450px',
                marginRight: '20%',
              }}
            />
          ) : (
            <img
              alt="Single Service"
              className="laserMachineHomeImg"
              src={image}
              style={{
                // background: 'red',
                height: '450px',
                width: '450px',
                marginRight: '20%',
              }}
            />
            // <ServiceImageSlider />
          )}
        </div>
        <div className="btnWrapDiv1">
          <Link
            to={Path}
            className="link"
            style={{
              textDecoration: 'none',
              // margin: 'auto',
              marginBottom: '30px',
            }}
          >
            <button
              style={{
                background: btnBg,
              }}
              className="single_service_btn"
              type="button"
              onClick={() => {
                scroll();
                if (title === 'Sell your laser') {
                  setFlag(true);
                } else {
                  setFlag(false);
                }
              }}
            >
              {btnText}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
